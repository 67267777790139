import {
  Box,
  Card,
  CardAction,
  CardBody,
  CardMedia,
  CardPrice,
  CardQuantity,
  IconCheck,
  Text,
} from '@butcherbox/freezer'
import { rem } from 'design'
import CardQuantityInput from 'design/components/CardQuantityInput/CardQuantityInput'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import {
  mutateDecrementAddon,
  mutateIncrementAddon,
} from '~/hooks/mutateAddons'
import { formatPriceFromCents } from '~/utils'
import CutInfoModal from '../CutInfoModal/CutInfoModal'
import { AddonCardProps } from './AddonCard.types'

export default function AddonCard({
  addon,
  addonsMap,
  displayViewIngredientsLink,
}: AddonCardProps) {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [increment] = mutateIncrementAddon()
  const [decrement] = mutateDecrementAddon()
  const incrementSku = React.useCallback(() => increment(addon), [
    increment,
    addon,
  ])
  const decrementSku = React.useCallback(() => decrement(addon), [
    decrement,
    addon,
  ])

  const quantity = addonsMap[addon.sku]?.quantity || 0
  const cardData = {
    cy: TEST_ID.CARD_ADDON,
    [`attr-sku`]: addon.sku,
    [`cy-current-quantity`]: quantity,
    [`cy-sku`]: addon.sku,
  }

  const infoClickHandler = () => {
    setModalOpen(true)
  }
  const onClose = () => {
    setModalOpen(false)
  }

  const cardOverlay = () => (
    <>
      <IconCheck customColor={{ base: 'white' }} size="small" />
      <Text color="white" component="span" variant="H4Condensed">
        In Your Box
      </Text>
    </>
  )

  function renderCardDetails() {
    return (
      <>
        <CardBody textAlign="center" variant="compact">
          <Box style={{ minHeight: '88px' }}>
            <Text component="h2" variant="H4Condensed">
              {addon.description}
            </Text>
            <Text color="stone" variant="Body1Regular">
              {addon.packSize}
            </Text>
          </Box>
          {addon.price !== 0 && (
            <CardPrice
              price={formatPriceFromCents(addon.price)}
              textVariant="H4Condensed"
              variant="centered"
            />
          )}
        </CardBody>
        <CardAction paddingBottom={8}>
          <CardQuantityInput
            h={rem(40)}
            handleAddItem={incrementSku}
            handleRemoveItem={decrementSku}
            product={addon}
            quantity={quantity}
            variant="addon"
          />
        </CardAction>
      </>
    )
  }

  return (
    <Card data={cardData} width="large">
      <CardMedia
        alt={addon.description}
        height={112}
        infoClickHandler={infoClickHandler}
        infoToolTipMessage="Cut Information"
        overlay={addonsMap[addon.sku]?.quantity > 0 ? cardOverlay : undefined}
        src={addon.image}
      >
        {addonsMap[addon.sku]?.quantity > 0 && (
          <CardQuantity value={addonsMap[addon.sku]?.quantity} />
        )}
      </CardMedia>

      {modalOpen ? (
        <CutInfoModal
          onClose={onClose}
          product={addon}
          quantity={quantity}
          renderButton={() => (
            <CardQuantityInput
              h={rem(40)}
              handleAddItem={incrementSku}
              handleRemoveItem={decrementSku}
              p={rem(8)}
              product={addon}
              pt={0}
              quantity={quantity}
              showInfoModalButton={displayViewIngredientsLink}
              variant="addon"
              w={rem(176)}
            />
          )}
          variant="addon"
        ></CutInfoModal>
      ) : null}
      {renderCardDetails()}
    </Card>
  )
}
