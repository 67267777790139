import React from 'react'
import HomepageBoxContainerUI from './HomepageBoxContainer.ui'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import {
  GIFT_BOXES_PATH_SHOP,
  GIFT_BOXES_PATH_STOREFRONT,
} from '~/data/constants'

const HomepageOTBContainer: React.FC = () => {
  const [isGiftLinkEnabled] = useOptimizelyFeature('ecom_810_remix_otb_rollout')

  return (
    <HomepageBoxContainerUI
      content={{
        subtitle: 'One-Time boxes',
        title: 'Limited-Edition Gifts',
        body: `The tastiest way to say thanks, celebrate, congratulate-you name it! Choose one of our gift boxes, perfect for any occasion.`,
        image: {
          alt: 'A ButcherBox one-time box',
          id: 'oneTimeBox',
        },
        link: {
          text: 'Shop Gifts',
          href: isGiftLinkEnabled
            ? GIFT_BOXES_PATH_SHOP
            : GIFT_BOXES_PATH_STOREFRONT,
          target: '_blank',
        },
        analyticsDataId: 'one-time-box',
      }}
      variant="imageRight"
    />
  )
}

export default HomepageOTBContainer
