import { Box, IconLoadingSpinner } from '@butcherbox/freezer'
import React from 'react'
import { withErrorBoundary } from '~/components/ErrorBoundary/ErrorBoundary'
import fallbackBackground from '~/images/member_day_hero_slide1.jpg'
import FeaturedDealFallback from './components/FeaturedDealFallback/FeaturedDealFallback'
import { FeaturedDealHero } from './components/FeaturedDealHero/FeaturedDealHero'
import { StandardHero } from './components/StandardHero/StandardHero'
import * as Styles from './FeaturedDealContainer.css'
import * as Types from './FeaturedDealContainer.types'
import useFeaturedDeal from './hooks/useFeaturedDeal'

function FeaturedDealContainer({
  ...boxProps
}: Types.FeaturedDealContainerProps) {
  const { featuredDeal, isLoading } = useFeaturedDeal()

  /* Render a loading version of the hero if we have yet to receive fetched content from API */
  if (isLoading) {
    return (
      <Box className={Styles.HeroContainerLoading} p={0}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          w="100%"
        >
          <IconLoadingSpinner />
        </Box>
      </Box>
    )
  }

  /**
   * If we're done fetching from API but the content is missing
   * or an error occurred, render a fallback hero
   */
  if (!featuredDeal) {
    throw new Error()
  }

  /**
   * If our fetched data has a deal associated with it,
   * render a deal-specific version of the hero
   */
  if (featuredDeal.deal) {
    const { deal, heroDescription, heroImage, heroTitle } = featuredDeal
    return (
      <FeaturedDealHero
        {...boxProps}
        deal={deal}
        description={heroDescription}
        image={heroImage ?? fallbackBackground}
        title={heroTitle}
      />
    )
  }

  /**
   * If our fetched data has CTA-related data, render the
   * standard version of our hero
   */
  if (featuredDeal.heroButtonText && featuredDeal.heroLink) {
    const {
      heroDescription,
      heroImage,
      heroTitle,
      heroButtonText,
      heroLink,
    } = featuredDeal

    return (
      <StandardHero
        {...boxProps}
        backgroundImage={heroImage ?? fallbackBackground}
        description={heroDescription}
        link={{
          text: heroButtonText,
          path: heroLink,
        }}
        title={heroTitle}
      />
    )
  }
}

// Wrap this component with an Error Boundary,
// If the API fails, invoke the fallback.
export default withErrorBoundary(FeaturedDealContainer, {
  fallback: <FeaturedDealFallback />,
})
