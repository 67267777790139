import React from 'react'
import { Box, Lockup, Text, ButtonLink } from '@butcherbox/freezer'
import GatsbyLink from 'gatsby-link'
import * as Styles from './WFDHomepageRibbon.css'
import ImageSetBackground from '~/components/ImageSetBackground/ImageSetBackground'
import { graphql, useStaticQuery } from 'gatsby'
import useLatestShipmentStatus from '~/hooks/useLatestShipmentStatus'

export const WFDHomepageRibbon = () => {
  const imageData = useStaticQuery(graphql`
    query {
      bg: contentfulImageSet(uniqueId: { eq: "wfd_ribbon_mhp" }) {
        ...ImageSet
      }
    }
  `)

  const { getIsWithinDaysOfLatestShipment } = useLatestShipmentStatus()

  return getIsWithinDaysOfLatestShipment(14) ? (
    <ImageSetBackground
      breakpoints={{ mobile: '(max-width: 767px)' }}
      imageSet={imageData.bg}
    >
      <Box className={Styles.Ribbon}>
        <Box className={Styles.RibbonText}>
          <Lockup textAlign="center">
            <Text color="white" variant="Subhead1">
              Plan meals for your cuts
            </Text>
            <Text color="white" variant="Body2Bold">
              Explore a collection of tasty recipes and handy cooking tips,
              custom made for the cuts in your upcoming box.{' '}
            </Text>
            <ButtonLink
              component={GatsbyLink}
              to="/account/whats-for-dinner"
              variant="secondary"
            >
              Explore Recipes &amp; Tips
            </ButtonLink>
          </Lockup>
        </Box>
      </Box>
    </ImageSetBackground>
  ) : null
}
