import { useQuery } from 'react-query'
import { CACHE_KEY_ADDONS } from '~/bb-api/constants'
import { GET_ADDONS } from '~/bb-api/endpoints'
import { Addon } from '~/bb-api/schemata'
import axios from '~/utils/axios'

export default function useAddons() {
  return useQuery(
    CACHE_KEY_ADDONS,
    () =>
      axios
        .get<{ data: Addon[] }>(GET_ADDONS)
        .then((response) => response.data.data),
    {
      staleTime: 10 * 60 * 1000, // 10 minutes
    }
  )
}
