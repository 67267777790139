import { Box, Button, Lockup, Text } from '@butcherbox/freezer'
import { OptimizelyContext } from '@optimizely/react-sdk'
import { Link } from 'gatsby'
import React from 'react'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import * as Styles from '~/routes/MemberHomepage/FeaturedDealContainer/FeaturedDealContainer.css'
import type { StandardHeroProps } from '~/routes/MemberHomepage/FeaturedDealContainer/FeaturedDealContainer.types'

export const StandardHero = ({
  backgroundImage,
  description,
  link,
  title,
  ...restProps
}: StandardHeroProps) => {
  const { optimizely } = React.useContext(OptimizelyContext)

  return (
    <Box className={Styles.HeroContainer} {...restProps}>
      <Box
        className={Styles.HeroImage}
        style={{ backgroundImage: `url('${backgroundImage}')` }}
      />
      <Box className={Styles.HeroWrapper}>
        <Box className={Styles.Deal}>
          <Lockup paddingBottom={24}>
            <Text component="h1" variant="DisplayTwo">
              {title}
            </Text>
            <Text component="p" variant="Body1Regular">
              {description}
            </Text>
          </Lockup>
          <Button
            component={Link}
            onClick={() =>
              optimizely?.track('Member Homepage Hero CTA Button Clicked')
            }
            size="fluid"
            to={link.path}
          >
            {link.text}
          </Button>
        </Box>
        <PanelBreak className={Styles.VerticalDealBreak} intensity={'light'} />
      </Box>
    </Box>
  )
}
