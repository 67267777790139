import { Box } from '@chakra-ui/core'
import { useFeature } from '@optimizely/react-sdk'
import { rem } from 'design'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { H2, H3 } from 'design/components/Typography/Typography'
import React from 'react'
import { Helmet } from 'react-helmet'
import type { User } from '~/bb-api/schemata'
import { ENVIRONMENT, getEnvironment } from '~/utils/env'

// yotpoWidgetsContainer is set by the Yotpo Modules Loader JS snippet
// no types are published and no JS package is currently available for this code
// so we need to declare what we expect to be present on window
declare global {
  interface Window {
    yotpoWidgetsContainer?: {
      initWidgets: () => void
    }
  }
}

/**
 * YotpoModule renders a DOM node that will be replaced by the Yotpo Modules Loader microfrontend.
 * Render this component when you want to render the Yotpo referral modules in the UI.
 *
 * This component encapsulates both the rendering of the modules loader instance as well as the
 * querying for the modules loader JavaScript bundle. This component uses React Helmet to elevate
 * the script load for the modules bundle, and it will not be re-requested even after a clientside
 * navigation.
 */
export default function YotpoModule({
  customerEmail,
  customerId,
}: React.PropsWithChildren<{
  customerEmail?: User['emailAddress']
  customerId?: User['id']
}>) {
  const [widgetsStatus, setWidgetsStatus] = React.useState<
    'loading' | 'loaded' | 'errored'
  >('loading')

  // track the loading status of the Yotpo module JS so we can show an error if it fails to load
  React.useEffect(() => {
    // no need to init if the widgets have already loaded
    if (widgetsStatus !== 'loading') return

    let retryCount = 0

    // retry init until yotpo module JS bundle is present
    const initIfYotpoPresent = () => {
      if (retryCount > 5) {
        setWidgetsStatus('errored')
      }

      if (retryCount === 5) {
        console.error(
          new Error('Failed to load Yotpo widgets container after retries')
        )
      }

      if (typeof window?.yotpoWidgetsContainer?.initWidgets !== 'function') {
        retryCount++
        setTimeout(initIfYotpoPresent, 500 * retryCount)
        return // retry
      }

      // Yotpo's modules loader is architected for server-rendered apps. We must re-initialize
      // each time this component is rendered, because the modules loader JavaScript will only
      // initialize once by default and not again after a clientside route change.
      window.yotpoWidgetsContainer.initWidgets() // assumes early return if not available
      setWidgetsStatus('loaded')
    }

    initIfYotpoPresent()
  }, [customerEmail, customerId, widgetsStatus])

  const { loaderScriptId, widgetInstanceId } = getModuleLoaderConfig()

  return (
    <>
      <Box
        minHeight={widgetsStatus !== 'errored' ? '100vh' : 'unset'}
        py={widgetsStatus === 'errored' ? rem(48) : ''}
        textAlign="center"
      >
        {widgetsStatus === 'loading' ? <LoadingSpinner /> : null}
        {widgetsStatus === 'errored' ? (
          <>
            <H2 pb={rem(8)}>Sorry, we couldn't load the referrals program.</H2>
            <H3>Please try again later.</H3>
          </>
        ) : null}

        {/* Prevent Yotpo from initializing without the user email or id */}
        {customerEmail && customerId ? (
          <>
            <Helmet>
              <script
                async
                src={`https://cdn-widgetsrepository.yotpo.com/v1/loader/${loaderScriptId}`}
              ></script>
            </Helmet>

            <div
              data-authenticated="true"
              data-email={customerEmail}
              data-id={customerId}
              id="swell-customer-identification"
              style={{ display: 'none' }}
            />
            <div
              className="yotpo-widget-instance"
              data-yotpo-instance-id={widgetInstanceId}
            />
          </>
        ) : null}
      </Box>
    </>
  )
}

/**
 * getModuleLoaderConfig returns the environment-specific data for rendering the Yotpo modules
 *
 * While we continue to serve the production frontend bundle from butcherbox.dev, this
 * function must be evaluated at runtime. The specific modules loaded by the browser
 * must be different depending on if the user is accessing staging versus production,
 * so we cannot bake that data in to the bundle.
 */
const getModuleLoaderConfig = () => {
  switch (getEnvironment(window?.location)) {
    case ENVIRONMENT.PRODUCTION:
      return {
        loaderScriptId: 'oTy-7UnQq9HppEzPp5irQg',
        widgetInstanceId: '26941',
      }
    default:
      return {
        loaderScriptId: 'gh9BreEl0ZshoJ9KmvheEQ',
        widgetInstanceId: '28732',
      }
  }
}

export const useYotpoModuleRollout = (): {
  renderModule: 'yotpo' | 'loading'
} => {
  const [isYotpoEnabled, , optimizelyTimeout] = useFeature(
    'yotpo_production_release'
  )

  switch (true) {
    case isYotpoEnabled:
      return { renderModule: 'yotpo' }
    case optimizelyTimeout:
    default:
      return { renderModule: 'loading' }
  }
}
