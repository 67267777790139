import { Box, Lockup, Text } from '@butcherbox/freezer'
import CardQuantityInput from 'design/components/CardQuantityInput/CardQuantityInput'
import React from 'react'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import {
  mutateDecrementInvoiceItem,
  mutateIncrementInvoiceItem,
} from '~/hooks/mutateInvoiceItem'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'
import { formatPriceFromCents } from '~/utils'
import * as Styles from '../../FeaturedDealContainer.css'
import type { FeaturedDealHeroProps } from '../../FeaturedDealContainer.types'

export const FeaturedDealHero = ({
  deal,
  description,
  image,
  title,
  ...restProps
}: FeaturedDealHeroProps) => {
  const { data: upcomingOrder } = useUpcomingOrder()
  const [increment] = mutateIncrementInvoiceItem()
  const [decrement] = mutateDecrementInvoiceItem()

  const incrementSku = React.useCallback(() => increment(deal), [
    deal,
    increment,
  ])

  const decrementSku = React.useCallback(() => decrement(deal), [
    decrement,
    deal,
  ])

  const quantity = React.useMemo(() => {
    const invoiceItem = upcomingOrder?.invoiceItems.find(
      (item) => item.sku === deal.sku
    )
    return invoiceItem ? invoiceItem.quantity : 0
  }, [deal, upcomingOrder])

  return (
    <Box className={Styles.HeroContainer} {...restProps}>
      <Box
        className={Styles.HeroImage}
        style={{ backgroundImage: `url('${image}')` }}
      />
      <Box className={Styles.HeroWrapper}>
        <Box className={Styles.Deal}>
          <Lockup paddingBottom={24}>
            <Text component="h1" variant="DisplayTwo">
              {title}
            </Text>
            <Text color="spicedCrimson" component="h2" variant="H2Bold">
              {formatPriceFromCents(deal.price)}
            </Text>
            <Text component="p" variant="Body1Regular">
              <span
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></span>
            </Text>
          </Lockup>

          <CardQuantityInput
            handleAddItem={incrementSku}
            handleRemoveItem={decrementSku}
            product={deal}
            quantity={quantity}
            variant="featuredDeal"
          />
        </Box>
        <PanelBreak className={Styles.VerticalDealBreak} intensity={'light'} />
      </Box>
    </Box>
  )
}
