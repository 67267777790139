import React from 'react'
import { useQuery } from 'react-query'
import { CACHE_KEY_MEMBER_FEATURED_DEAL } from '~/bb-api/constants'
import { GET_FEATURED_DEAL } from '~/bb-api/endpoints'
import { FeaturedDeal } from '~/bb-api/schemata'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'

const useFeaturedDeal = () => {
  const user = React.useContext(UserContext)
  const { data: featuredDeal, isLoading } = useQuery(
    CACHE_KEY_MEMBER_FEATURED_DEAL,
    () =>
      axios
        .get<FeaturedDeal>(GET_FEATURED_DEAL(user.id))
        .then((res) => res.data),
    {
      enabled: !!user?.id,
      retry: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    }
  )
  return { featuredDeal, isLoading }
}

export default useFeaturedDeal
