import React from 'react'
import AuthenticatedLayout from '~/layouts/AuthenticatedLayout'
import MemberHomepage from '~/routes/MemberHomepage/MemberHomepage'

export default (props) => {
  return (
    <AuthenticatedLayout title="Welcome Back" {...props}>
      <MemberHomepage />
    </AuthenticatedLayout>
  )
}
