import { Box, ButtonLink, Lockup, Text } from '@butcherbox/freezer'
import { Link } from 'gatsby'
import React from 'react'
import fallbackBackground from '~/images/member_day_hero_slide1.jpg'
import * as Styles from '../../FeaturedDealContainer.css'

const FeaturedDealFallback = () => (
  <Box className={Styles.HeroContainer}>
    <Box
      className={Styles.HeroImage}
      style={{ backgroundImage: `url(${fallbackBackground})` }}
    />
    <Box className={Styles.HeroWrapper}>
      <Box className={Styles.Deal}>
        <Lockup paddingBottom={24}>
          <Text component="h1" variant="DisplayTwo">
            Just Added
          </Text>
          <Text variant="Body1Regular">Save on member exclusive deals.</Text>
        </Lockup>
        <ButtonLink component={Link} to="/account/deals">
          Shop Now
        </ButtonLink>
      </Box>
    </Box>
  </Box>
)

export default FeaturedDealFallback
