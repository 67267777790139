import { Box, IconLoadingSpinner } from '@butcherbox/freezer'
import React from 'react'
import { WFDHomepageRibbon } from '~/components/WFDHomepageRibbon'
import { TEST_ID } from '~/constants/cypress'
import { UserContext } from '~/context/user'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import AddonContainer from '~/routes/MemberHomepage/AddonContainer'
import DealContainer from '~/routes/MemberHomepage/DealContainer'
import FeaturedDealContainer from '~/routes/MemberHomepage/FeaturedDealContainer/FeaturedDealContainer'
import HomepageCatalogContainer from '~/routes/MemberHomepage/HomepageBoxContainer/HomepageCatalogContainer'
import HomepageOTBContainer from '~/routes/MemberHomepage/HomepageBoxContainer/HomepageOTBContainer'
import YotpoModule, { useYotpoModuleRollout } from '~/sections/YotpoModule'
import HomePageGiveAway from './HomePageGiveAway'
import * as Styles from './MemberHomepage.css'

export default function MemberHomepage() {
  const user = React.useContext(UserContext)
  const { renderModule } = useYotpoModuleRollout()
  const [julyGiveAway] = useOptimizelyFeature('july_give_away_2023')

  return (
    <>
      <FeaturedDealContainer data-cy={TEST_ID.MEMBER_HOMEPAGE_FEATURED_DEAL} />
      {julyGiveAway && <HomePageGiveAway />}
      <WFDHomepageRibbon />
      <>
        <HomepageCatalogContainer />
        <DealContainer
          className={Styles.SectionContainer.haze}
          data-cy={TEST_ID.MEMBER_HOMEPAGE_DEALS}
          productListCategory="memberDeal-memberHomepage"
        />

        <AddonContainer
          className={Styles.SectionContainer.white}
          data-cy={TEST_ID.MEMBER_HOMEPAGE_ADDONS}
        />
      </>

      <HomepageOTBContainer />

      <Box className={Styles.YotpoFlex}>
        <Box className={Styles.YotpoModule}>
          {(() => {
            switch (renderModule) {
              case 'yotpo':
                return (
                  <YotpoModule
                    customerEmail={user?.emailAddress}
                    customerId={user?.id}
                  />
                )
              case 'loading':
              default:
                return (
                  <Box className={Styles.YotpoSpinnerWrap}>
                    <IconLoadingSpinner />
                  </Box>
                )
            }
          })()}
        </Box>
      </Box>
    </>
  )
}
