import { Box, Text, ButtonLink, ButtonLinkProps } from '@butcherbox/freezer'
import { graphql, useStaticQuery, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import HomepageContainerHeader from '../HomepageContainerHeader'
import { HomepageBoxContainerProps } from './HomepageBoxContainer.types'
import * as Styles from './HomepageBoxContainer.css'

const HomepageBoxContainerUI = ({
  variant,
  content,
}: HomepageBoxContainerProps) => {
  const linkAttributes = content.link.href.includes('http')
    ? { href: content.link.href }
    : { to: content.link.href, component: Link }

  const imageData = useStaticQuery(graphql`
    query {
      oneTimeBox: file(relativePath: { eq: "holiday-gift-one-time-box.jpg" }) {
        childImageSharp {
          fluid(cropFocus: ATTENTION) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      catalog: file(relativePath: { eq: "different-meat-in-pan.png" }) {
        childImageSharp {
          fluid(cropFocus: ATTENTION) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Box
      className={Styles.HomepageBoxContainer[variant]}
      data-attr-container-content={content.analyticsDataId}
      data-what="homepage-box-container"
    >
      <Box className={Styles.HomepageBoxText[variant]}>
        <HomepageContainerHeader
          subtitle={content.subtitle}
          title={content.title}
        />

        <Box className={Styles.DescriptionText}>
          <Text variant="Body1Regular">{content.body}</Text>
        </Box>

        <ButtonLink
          {...(linkAttributes as ButtonLinkProps)}
          data={{ what: 'button' }}
          size="fluid"
          target={content.link.target}
        >
          {content.link.text}
        </ButtonLink>
      </Box>

      <BackgroundImage
        alt={content.image.alt}
        className={Styles.Image}
        fluid={imageData[content.image.id].childImageSharp.fluid}
      />
    </Box>
  )
}

export default HomepageBoxContainerUI
