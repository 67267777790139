import React from 'react'
import { Box, Lockup, Text } from '@butcherbox/freezer'

export default ({
  errorMessage,
}: {
  errorMessage?: string
  error?: unknown
}) => {
  return (
    <Box alignItems="center" display="flex" justifyContent="center" p={32}>
      <Lockup>
        <Text variant="Title">Oh no!</Text>
        <Text variant="Body1Regular">
          {errorMessage
            ? errorMessage
            : `We're having an issue right now with your request, please try
      again later.`}
        </Text>
      </Lockup>
    </Box>
  )
}
