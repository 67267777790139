import { Box, Text, TextLink } from '@butcherbox/freezer'
import React from 'react'

const HomePageGiveAway = () => {
  return (
    <Box
      background={'black'}
      paddingBottom={8}
      paddingTop={8}
      textAlign={'center'}
    >
      <Text
        color={'white'}
        component="p"
        textTransform="capitalize"
        variant="H3Bold"
      >
        {`For more giveaway details, `}
        <TextLink
          href={
            'https://justcook.butcherbox.com/grilling-week-giveaway-july-2023/'
          }
          target="blank"
          theme="white"
          underline={true}
        >
          click here.
        </TextLink>
      </Text>
    </Box>
  )
}

export default HomePageGiveAway
