import { Lockup, Text, TextProps } from '@butcherbox/freezer'
import React from 'react'

export const MOBILE_MARGIN_BOTTOM = 16
export const DESKTOP_MARGIN_BOTTOM = 23

export type IHomepageContainerHeader = {
  title: string
  subtitle: string
} & TextProps

export default function HomepageContainerHeader({ title, subtitle, ...props }) {
  return (
    <Lockup marginBottom={16}>
      <Text variant="Eyebrow">{subtitle}</Text>
      <Text variant="DisplayTwo" {...props}>
        {title}
      </Text>
    </Lockup>
  )
}
