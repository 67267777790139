import {
  Box,
  BoxProps,
  ButtonLink,
  Text,
  VisuallyHidden,
} from '@butcherbox/freezer'
import { shortId } from 'design'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import AddonCard from '~/components/AddonCard/AddonCard'
import * as Styles from '~/components/AddonCard/AddonCard.css'
import useAddons from '~/hooks/useAddons'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'
import HomepageContainerHeader from '../HomepageContainerHeader'

const AddonContainer: React.FC<BoxProps> = (props) => {
  const ariaLabel = React.useMemo(() => shortId(), [])
  const { data: addons = [], isLoading: addonsLoading } = useAddons()
  const {
    data: upcomingOrder,
    isLoading: upcomingOrderLoading,
  } = useUpcomingOrder()

  // Create a map for faster lookup of quantity in individual deal cards
  const addonsMap = React.useMemo(
    () =>
      upcomingOrder?.subscription.box.addons.reduce((obj, addon) => {
        obj[addon.sku] = addon
        return obj
      }, {}) || {},
    [upcomingOrder?.subscription.box.addons]
  )

  const loading = addonsLoading || upcomingOrderLoading
  const [isIngredientEnabled] = useOptimizelyFeature('shop-426_ingredient_link')

  return (
    <Box textAlign={'center'} {...props}>
      <HomepageContainerHeader
        id={ariaLabel}
        subtitle="In Every Box"
        title="Recurring Add-ons"
      />

      <VisuallyHidden>
        Addons are billed and shipped with every box until you remove them
      </VisuallyHidden>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box marginTop={24}>
          {addons.length ? (
            <Box as="ul" className={Styles.AddonGrid}>
              {addons.slice(0, 4).map((product) => (
                <AddonCard
                  addon={product}
                  addonsMap={addonsMap}
                  displayViewIngredientsLink={isIngredientEnabled}
                  key={product.sku}
                />
              ))}
            </Box>
          ) : (
            <Text>No add-ons are currently available, check back soon!</Text>
          )}

          {addons.length > 4 && (
            <ButtonLink
              href={'/account/addons'}
              marginTop={40}
              size={'standard'}
            >
              View All Add-ons
            </ButtonLink>
          )}
        </Box>
      )}
    </Box>
  )
}

export default AddonContainer
