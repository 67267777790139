import { shortId } from 'design'
import React from 'react'
import { trackProductListViewed } from '~/analytics/events'
import { ProductListCategory } from '~/analytics/types'
import ModalPastDue from '~/components/AccountPastDueModal'
import { SubscriptionContextRemote } from '~/context/subscriptionRemote'
import useDeals from '~/hooks/useDeals'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'
import DealsGridLayout from '~/routes/AccountDeals/DealsGridLayout/DealsGridLayout'
import HomepageContainerHeader from '../HomepageContainerHeader'
import { Box, BoxProps, ButtonLink, VisuallyHidden } from '@butcherbox/freezer'

const DealContainer: React.FC<
  { productListCategory: ProductListCategory } & BoxProps
> = (props) => {
  const ariaLabel = React.useMemo(() => shortId(), [])
  const { data: deals = [], isLoading: dealsLoading } = useDeals()
  const {
    data: upcomingOrder,
    isLoading: upcomingOrderLoading,
  } = useUpcomingOrder()

  // Create a map for faster lookup of quantity in individual deal cards
  const upcomingOrderMap = React.useMemo(
    () =>
      upcomingOrder?.invoiceItems.reduce((obj, invoiceItem) => {
        obj[invoiceItem.sku] = invoiceItem
        return obj
      }, {}) || {},
    [upcomingOrder?.invoiceItems]
  )

  const loading = dealsLoading || upcomingOrderLoading

  const { subscription } = React.useContext(SubscriptionContextRemote)
  const subscriptionStatus =
    subscription !== undefined ? subscription.status : 'not_found'

  const [modalOpen, setModalOpen] = React.useState(false)
  const displayPastDueModal = React.useCallback(
    (display) => {
      setModalOpen(display)
    },

    []
  )
  const handleModalClose = React.useCallback(() => setModalOpen(false), [])

  const orderedProducts = deals.slice(0, 3)

  React.useEffect(() => {
    if (loading) return

    trackProductListViewed({
      orderedProducts: orderedProducts,
      productCategory: 'memberDeal',
      productListCategory: props.productListCategory,
    })
  }, [loading, orderedProducts, props.productListCategory])

  return (
    <>
      <Box {...props}>
        <Box marginBottom={24} textAlign="center">
          <HomepageContainerHeader
            id={ariaLabel}
            subtitle="Next Box Only"
            textAlign="center"
            title="Exclusive Member Deals"
          />
        </Box>
        <VisuallyHidden>
          Member deals are one-time products that can be added to your upcoming
          box order
        </VisuallyHidden>
        <DealsGridLayout
          deals={orderedProducts}
          disableIndividualDeal
          displayPastDueModal={displayPastDueModal}
          loading={loading}
          productListCategory="memberDeal-memberHomepage"
          subscriptionStatus={subscriptionStatus}
          upcomingOrderMap={upcomingOrderMap}
        />
        <ModalPastDue
          handleModalClose={handleModalClose}
          isDisplayModal={modalOpen}
        />
        {deals.length > 3 && (
          <Box textAlign="center">
            <ButtonLink
              href={'/account/deals'}
              marginTop={40}
              size={'standard'}
            >
              View All Member Deals
            </ButtonLink>
          </Box>
        )}
      </Box>
    </>
  )
}

export default DealContainer
