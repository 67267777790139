import React from 'react'
import FallbackFull from './fallbacks/Fallback.Full'
import FallbackSmall from './fallbacks/Fallback.Small'
import { trackDataDogErrorBoundaryEvent } from '~/analytics/events'

interface ErrorBoundaryProps {
  fallback?: React.ReactNode
  errorMessage?: string
  variant?: 'full' | 'small'
  linkDestination?: string
  linkText?: string
  errorHandler?: (error: unknown, errorInfo: React.ErrorInfo) => void
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean; error: null | unknown }
> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error })
    // You can log the error to an error reporting service
    trackDataDogErrorBoundaryEvent(
      'hit_error_boundary',
      this.props.errorMessage
    )
    if (this.props.errorHandler) {
      this.props.errorHandler(error, errorInfo)
    }
  }

  render() {
    const {
      fallback,
      errorMessage,
      linkDestination = '/',
      linkText = 'Butcherbox Home',
      variant = 'full',
    } = this.props

    let Fallback: typeof FallbackFull

    switch (variant) {
      case 'full':
        Fallback = FallbackFull
        break
      case 'small':
        Fallback = FallbackSmall
        break
      default:
        break
    }

    if (this.state.hasError) {
      return (
        fallback ?? (
          <Fallback
            error={this.state.error}
            errorMessage={errorMessage}
            linkDestination={linkDestination}
            linkText={linkText}
          />
        )
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary

export const withErrorBoundary = <P extends object>(
  Component: React.ComponentType<P>,
  options: ErrorBoundaryProps
) =>
  class WithErrorBoundary extends React.Component<P> {
    render() {
      const { ...props } = this.props
      return (
        <ErrorBoundary {...options}>
          <Component {...(props as P)} />
        </ErrorBoundary>
      )
    }
  }
