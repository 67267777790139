import React from 'react'
import HomepageBoxContainerUI from './HomepageBoxContainer.ui'

const HomepageCatalogContainer: React.FC = () => {
  return (
    <HomepageBoxContainerUI
      content={{
        subtitle: 'By Protein',
        title: 'Shop The Catalog',
        body: `Find your perfect cut on our catalog, featuring a wide variety of member-favorite products. Browse by protein to fill your next box.`,
        image: {
          alt: 'Pork, Steak, Chicken, and Salmon in a pan.',
          id: 'catalog',
        },
        link: {
          text: 'Shop The Catalog',
          href: '/account/deals?lander=catalog',
          target: '_self',
        },
        analyticsDataId: 'catalog',
      }}
      variant="imageLeft"
    />
  )
}

export default HomepageCatalogContainer
